<template>
    <div class="w-full">
        <EnvelopeModal :controller="createModal" @close="closeCreateModal" />
        <AddOnsModal v-if="addOnModal" :openModal="addOnModal" :transaction="currentTransaction" @close="addOnModal=false" />

        <a-card :bodyStyle="{padding:0, borderTop:'1px solid var(--light-purple)'}" :title="currentTransaction.type === 'purchase' ? 'Agreement of Purchase' : 'Agreement of Lease'">
            <span slot="extra" v-if="currentTransaction.type === 'purchase'">
                OFFER TYPE: <span class="text-primary ml-1" style="background: rgba(255, 255, 2, 0.3);padding: 2px 8px;position: relative;display: inline-block;"><b>{{purchaseOfferLabel}}</b></span>
                <strong v-if="conditionalHTML" class="ml-3" style="background: rgba(255, 255, 2, 0.3);padding: 2px 8px;position: relative;display: inline-block;" v-html="conditionalHTML"></strong>
            </span>
            <EnvelopeTable :key="$store.state.appData.newStoreTime" :main="true" @editEnvelope="editEnvelope"/>
            <template slot="actions" v-if="editingRights && needsRelease && mainSigned">
                <div class="text-left w-full pl-4" style="cursor: auto;">
					<a-tooltip overlayClassName="change-tooltip-color" title="Release Envelopes can be used to void this Transaction">
                        <a href="#" v-if="needsRelease" @click.prevent="addMutualRelease" style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
                            <a-icon type="plus-circle" class="mr-2" />
                            <span>Add Notice of Mutual Release</span>
                        </a>
                    </a-tooltip>
                </div>
            </template>
        </a-card>

		<a-card v-if="currentTransaction.reservation && currentTransaction.reservation.envelops"  class="mt-6" :bodyStyle="{padding:0, borderTop:'1px solid var(--light-purple)'}" title="Agreement of Reservation">
            <EnvelopeTable tableFor='reservation' :key="$store.state.appData.newStoreTime" :main="true" @editEnvelope="editEnvelope"/>
        </a-card>

        <a-card  class="mt-6" :bodyStyle="{padding:0, borderTop:'1px solid var(--light-purple)'}" title="Amendments or Documents that require Approval / Signature">
            <EnvelopeTable :key="$store.state.appData.newStoreTime" :amendment-docs="true" @editEnvelope="editEnvelope"/>
            <template slot="actions" v-if="editingRights">
                <div class="text-left w-full pl-4" style="cursor: auto;">
                    <a href="#" @click.prevent="e => createEnvelope()" style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
                        <a-icon type="plus-circle" class="mr-2" /> Create Amendment/Document
                    </a>
                </div>
            </template>
        </a-card>

		<a-card v-if="instance.productType === 'highrise'"  class="mt-6" :bodyStyle="{padding:0, borderTop:'1px solid var(--light-purple)'}" title="Add Ons Contracts">
            <EnvelopeTable tableFor='addOns' :key="$store.state.appData.newStoreTime" :main="true" @editEnvelope="editEnvelope"/>
            <template slot="actions" v-if="editingRights">
                <div class="text-left w-full pl-4" style="cursor: auto;">
                    <a href="#" @click.prevent="addOnModal=true" style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
                        <a-icon type="plus-circle" class="mr-2" /> Create add ons contract
                    </a>
                </div>
            </template>
        </a-card>

        <a-card :bodyStyle="{padding:0, borderTop:'1px solid var(--light-purple)'}" class="mt-6" >
            <div slot=title class="dF w-full jSB">
                <div>
                    Supporting Documents
                </div>
                <div v-if="editingRights">
                    <label for="contract-file" style="display:inline-flex"  class="ant-btn ant-btn-primary ant-btn-background-ghost dF aC" ant-click-animating-without-extra-node="false">
                        <!-- <a-button @click.prevent="doNothing"  icon="upload" type="primary" ghost>UPLOAD FILE</a-button> -->
                       <i aria-label="icon: upload" class="anticon anticon-upload">
                           <svg viewBox="64 64 896 896" data-icon="upload" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                       </i>
                       <span>UPLOAD FILE</span>
                    </label>
                    <input id="contract-file" style="display:none" type="file" name="file" multiple @change="getFile" />
                </div>

            </div>
            <EnvelopeTable :key="$store.state.appData.newStoreTime" :docs-only="true" @editEnvelope="editEnvelope"/>
            <template slot="actions" v-if="editingRights">
                <div class="text-left w-full pl-4" style="cursor: auto;">
                    <a href="#" @click.prevent="addDoc" style="display:inline-block; cursor:pointer;width:auto" class="hover:text-primary">
                        <a-icon type="plus-circle" class="mr-2" /> Add Document
                    </a>
                </div>
            </template>
        </a-card>
    </div>
</template>

<script>
import EnvelopeTable from '@/components/transaction/EnvelopeTable'
import AddOnsModal from '@/components/transaction/AddOnsModal'
import EnvelopeModal from '@/components/transaction/EnvelopeModal'
import {diffDays} from 'bh-mod'
export default {
    components:{
        EnvelopeTable,
		EnvelopeModal,
        AddOnsModal
    },
    data(){
        return {
            formData:null,
            createModal:{
                show:false,
                type:'regular',
                envelope:false
            },
			addOnModal: false
        }
    },

    computed:{
        conditionalHTML(){
            let result = false
            let transaction = this.currentTransaction
            if (transaction.offerType === 'conditional'){
                let {graceEndDate} = transaction
                // graceEndDate = new Date('April 19 2022 11:59 pm').getTime()
                if (!isNaN(graceEndDate) && graceEndDate > Date.now()){
                    let days = diffDays(graceEndDate)
                    if (days >= 0){
                        days = days === 0? 'today' : `in ${days} day(s)`
                    }
                    result = `Firms up ${days}`
                }
            }
            return result
        },
        mainSigned(){
            return this.currentTransaction.envelopes.find(e => e.main && e.status.includes('complete') )
        },
        needsRelease(){
            return this.editingRights && this.$store.getters.needsRelease
        },
        purchaseOfferLabel(){
            if (this.currentTransaction.offerType === 'firm') return 'FIRM'
            return 'CONDITIONAL OFFER'
        },
        editingRights(){
            if (this.currentTransaction.status === 'void') return false
            let canEdit = false
            if (this.$p >= 20){
                return true
            } else {
                this.currentTransaction.owners.forEach( x => {
                    if (x.id == this.user.id){
                        canEdit = true
                    }
                })
                return canEdit
            }
        },
        user(){
            return this.$store.state.user.user
        },
        currentTransaction(){
            return this.$store.state.appData.currentTransaction
        },
        envelopes(){
            return this.currentTransaction.envelopes.filter(x => x.esign || x.isManual)
        },
		instance(){
            return this.$store.state.instance
        },
    },
    methods:{
        async getFile(e) {
            if (!e.target.files.length) return
            this.formData = new FormData()

            this.formData.set('files',e.target.files[0])
            let file =this.formData.get('files');

            if (!file) return this.$message.error('An error occurred!')

            this.formData.set('name',file.name)

            this.$store.commit('LOAD',true)

            this.$api.post(`/envelops/:instance/${this.currentTransaction.id}/file`,this.formData).then ( ({data}) => {
                this.$store.commit('LOAD',false)
                this.$store.commit('ADD_ENVELOPE', data)
            }).catch( (err) => {
				this.$store.commit('LOAD',false)
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})

        },

		addMutualRelease() {
			this.$confirm({
				title: 'Add Notice of Mutual Release',
				content: 'By adding a Notice of Mutual Release in this section, the transaction will be VOIDED upon completion of signing. Ensure this action is intentional before proceeding.',
				okText: 'Yes',
				okType: 'danger',
				cancelText: 'No',
				onOk: () => {
					this.createEnvelope('release')
				},
			})
		},

		createEnvelope(type = 'regular') {
			this.createModal = {
				show: true,
				type,
				envelope: true,
				isAmendment: true
			}
		},
		editEnvelope(envelope) {
			this.createModal = {
				show: true,
				type: envelope.type,
				envelope: true,
				isAmendment: true,
				envelopeData: envelope
			}
		},
        addDoc(){
            this.createModal = {
                show:true,
                type:'regular',
                envelope:false,
            }
        },
        closeCreateModal(){
            this.createModal.show = false
        },
    },
}
</script>

<style>

</style>
